import React,{Component} from 'react';
import axios from 'axios';
import {Form,Button} from 'react-bootstrap'
import { EditorState,convertToRaw} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {stateToHTML} from 'draft-js-export-html'; 
import {withRouter} from 'react-router-dom';

class EmailComponent extends Component {
    emptyItem = {
        email: ''
      };
    constructor(props){
        super(props)
        this.state = {
            customer: this.emptyItem,
            editorState: EditorState.createEmpty(),
            message:'',
            isValid:true,
            color:'red',
            visibility:false 
          };
        this.handleSubmit=this.handleSubmit.bind(this);
        this.handleChange=this.handleChange.bind(this);
        this.onEditorStateChange=this.onEditorStateChange.bind(this);

        
    }
    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let customer = {...this.state.customer};
        customer[name] = value;
        this.setState({customer});
      }
      onEditorStateChange (editorState)  {
          console.log("editorState",editorState)
        this.setState({
          editorState,
        });
      };
      
    checkValidity=()=>{
      if(this.state.customer.email===null || this.state.customer.email===undefined){
        this.setState({message:'Please enter valid Email'});
        this.setState({isValid:false})
        this.setState({color:'red'});

        return false;        
      }
      else if(!this.state.customer.email.includes('@')){
          this.setState({message:'Please enter valid Email'});
          this.setState({isValid:false})
          this.setState({color:'red'});

          return false;
      }
      else{
        this.setState({message:'Thank you for your response, and we will contact you soon'});
        this.setState({isValid:true})
        this.setState({color:'green'});

        return true;
     
      }
    }
    async handleSubmit(event) {
      this.setState({visibility:true})

        event.preventDefault();
        let jsonNotes = convertToRaw(this.state.editorState.getCurrentContent())
         let htmlNotes = stateToHTML(this.state.editorState.getCurrentContent())
        console.log("this.state",this.state)
        console.log("jsonNotes",jsonNotes)  
        console.log("htmlNotes",htmlNotes)  

        let valid=this.checkValidity();
        if(valid){
          const data ={ 
           "email": this.state.customer.email,
           "notes":htmlNotes
           };
                  try{
               
                          let jsonData = JSON.stringify(data)
      
                            let result = await axios.post(`https://a1qf7il1t5.execute-api.us-east-1.amazonaws.com/dev/email`,jsonData,{headers: {'Content-Type': 'application/json'}})
                             console.log(result);
                     }
                     catch(e){
                         console.log(e);
                     }
  
         }
         setTimeout(function(){ window.location = "/Home"; },3000);

  
          
    }
     render(){
        const { editorState } = this.state;
        let visibility=this.state.visibility;
        const message= visibility? (<div>
            <label style={{color:this.state.color}} >
                {this.state.message}
            </label>
        </div>):null

        return (
            <div className='App'>
                <br/>
                {message}

                <h3 className="title">Enter Your Contact Information:</h3>
                    <Form>
                 <Form.Group>
                    <input type="text" className="form-control" name="email" id="email" value={this.state.customer.email || ''}
                        onChange={this.handleChange} autoComplete="email" placeholder="Enter email address"/>
                </Form.Group>
                <Form.Group>
                    <Form.Label  className="form-label">Please enter text message</Form.Label>
                    <Editor editorState={editorState}  wrapperClassName="demo-wrapper" editorClassName="demo-editor" onEditorStateChange={this.onEditorStateChange}
                    toolbar={{
                        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker'],
                        inline: {
                          inDropdown: false,
                          className: undefined,
                          component: undefined,
                          dropdownClassName: undefined,
                          options: ['bold', 'italic', 'underline', 'strikethrough'],
                         
                        },
                        blockType: {
                          inDropdown: true,
                          options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
                          className: undefined,
                          component: undefined,
                          dropdownClassName: undefined,
                        },
                        fontSize: {
                          options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
                          className: undefined,
                          component: undefined,
                          dropdownClassName: undefined,
                        },
                        fontFamily: {
                          options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
                          className: undefined,
                          component: undefined,
                          dropdownClassName: undefined,
                        },
                        list: {
                          className: undefined,
                          component: undefined,
                          dropdownClassName: undefined,
                          options: ['unordered', 'ordered', 'indent', 'outdent'],
                        
                        },
                        textAlign: {
                          inDropdown: false,
                          className: undefined,
                          component: undefined,
                          dropdownClassName: undefined,
                         
                        },
                        colorPicker: {
                          className: undefined,
                          component: undefined,
                          popupClassName: undefined,
                          colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
                            'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
                            'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
                            'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
                            'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
                            'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
                        }
                        
                      }}/>
                    
                </Form.Group>
               
                 <Form.Group>
                <Button variant="primary" type="button" className="btn btn-primary" onClick={this.handleSubmit}>Submit</Button>
                </Form.Group>       
                </Form>
              </div>
        
            );
    }
   
};
    

  export default  withRouter(EmailComponent);