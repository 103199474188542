import React from 'react';
import styled from 'styled-components';

import {Nav,Container} from 'react-bootstrap';

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;

  li {
    padding: 18px 10px;
  }

  @media (max-width: 768px) {
    flex-flow: column nowrap;
    background-color: #0D2538;
    color:#fff;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 0;
    right: 0px;
    height: 100vh;
    width: 300px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;

    li {
      color: #fff;
    }
  }
`;

const RightNav = ({ open }) => {
  return (
    <Container>

    <Ul open={open}>              
       <li><Nav.Link id="navLInk" href="/About">About</Nav.Link></li>
       
      <li><Nav.Link id="navLInk" href="/Contact">Contact</Nav.Link></li>
      <li> <Nav.Link id="navLInk" href="/Home"><i className="fa fa-home" aria-hidden="true" style={{fontSize:'22px'}}></i></Nav.Link></li>
      <li> <Nav.Link id="navLInk" exact="true" href="/"></Nav.Link></li>
    </Ul>
    </Container>

  )
}

export default RightNav
