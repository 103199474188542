import React,{Component} from 'react';
import {withRouter} from 'react-router-dom';
import ControlledCarousel from './ControlledCarousel.js'
import {Nav} from 'react-bootstrap';

class Home extends Component {
  constructor(props){
    super(props)
    this.state={
      index:0
    }
   }

    render(){
    
      return (
        <>
         <div className="intro_parent" id="home"  >

                    <div className="main">
                       <ControlledCarousel/>
                   </div>
                   <div className="box1">
                     <div className="sub-title-wrapper">
                      <h3 className="sub_title" style={{textAlign:'center'}}>
                        Our Solutions
                      </h3>
                     </div>
                     <div className="box2"  style={{'backgroundColor': '#FFF'}}>
                        <div className="box3">
                          <Nav>
                              <Nav.Link className="section_subtitle" onClick={()=> window.open("https://bold.esambhav.com/", "_blank")}>                             
                                <img src={require('../images/bold.png')} style={{width:'100%', height:'222','objectFit': 'contain'}} alt=" BOLDigital" fluid="true"/>
                              </Nav.Link>
                              
                          </Nav>
                          <p className='main_section' style={{'borderLeft': '5px solid #3f48cc'}}>BOLDigital – Shipping and Logistics businesses are faced with the challenges of managing complex financial and administrative processes involved in the end-to-end life-cyle of Bill of Lading (BOL). Sambhav brings BOLD, a Digital Platform....
                          <Nav.Link className="section_subtitle" onClick={()=> window.open("https://bold.esambhav.com/", "_blank")}>
                          <i className="fas fa-external-link-alt"></i> 
                              </Nav.Link>
                          <br>
                          </br>
                          </p>
                        
                        </div>
                        <div className="space">
                          <br></br>
                        </div>
                        <div className="box3">
                        <Nav>
                              <Nav.Link className="section_subtitle"  onClick={()=> window.open("https://swaasa.esambhav.com/", "_blank")}>
                              <img src={require('../images/swaasa-ai.jpg')} style={{width:'100%',height:'222','objectFit': 'contain'}} alt=" Swaasa.AI" fluid="true"/>
                              </Nav.Link>                            
                          </Nav>
                          
                         <p className='main_section' style={{'borderLeft': '5px solid #f4ae55'}}>SWAASA.AI - As the world fights with COVID-19, businesses are faced with a tough decision of bringing their employees back to work and ensure their safety in a scalable and cost-effective manner.. SWAASA.ai brings AI/MachineLearning Technolgy and audiometric analysis together<br></br> 
                          <Nav.Link className="section_subtitle" onClick={()=> window.open("https://swaasa.esambhav.com/", "_blank")}>
                          Learn more about SWAASA.AI ...<i className="fas fa-external-link-alt"></i> 
                              </Nav.Link>
                          <br>
                          </br> </p>
                         </div>
                     </div>
                   </div>

              </div>
           </>             
      );
 
    }
    
}

export default withRouter(Home);