import React   from 'react';
import './App.css';
import Home from './components/Main';
import Footer from './components/Footer';
import About from './components/About'
import {Route,Switch} from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {Container} from 'react-bootstrap';
import RightNav from './components/Navbar';
import EmailComponent from './components/EmailComponent';

function App(props) {
  return (
      <div className="App">
        
           <div className="header">
        <div className="logo">
                 <a href="https://www.esambhav.com"> 
                 <img src={require('./images/logo.jpg')} style={{width:'100%',height:'auto'}} alt="logo" fluid="true"/></a>
              </div>
              <div className="menu">             

                  <Container>
                    <RightNav/>
                  </Container>
              </div>
            </div>      
           <div className="body">           
           <Switch>
                  <Route path="/Home">
                    <Home />
                  </Route>
                  <Route path="/About">
                    <About />
                  </Route>
                  <Route path="/Contact">
                    <EmailComponent />
                  </Route>
                      <Route exact path="/">
                    <Home />
                  </Route>
                </Switch>
    
           </div>
           <Footer/>
        </div>
  )
}

export default App;
