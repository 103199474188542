import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';



function ControlledCarousel() {
    const [index, setIndex] = useState(0);
  
    const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);
    };
  
    return (
      <Carousel activeIndex={index}  onSelect={handleSelect} className="carousel-overlay" responsive="true">
        <Carousel.Item className="carousel-item-div">
            <img
                id="carousel"
                className="d-block w-100"
                src={require('../images/home.jpg')}
                alt="First slide"
                fluid="true"
              />
            <p className="section_title">
            Digital Transformation<br></br>
            </p>
        </Carousel.Item>
        <Carousel.Item  className="carousel-item-div">

            <img
                id="carousel"
                className="d-block w-100"
                src={require('../images/home4.jpg')}
                alt="First slide"
                fluid="true"
              />
            <p className="section_title">
                API-led Connectivity<br></br>
                Enterprise Integrations<br></br>
                Business Process Management<br></br>

            </p>
        </Carousel.Item>
        <Carousel.Item  className="carousel-item-div">
 
  
         <img
            id="carousel"
            className="d-block w-100"
            src={require('../images/home3.jpg')}
            alt="First slide"
            fluid="true"
          />
            <p  className="section_title">
                Cloud Technologies<br></br>
                Artificial Intelligence and Machine Learning
            </p>
        </Carousel.Item>
        
      </Carousel>
    );
  }
 
  export default ControlledCarousel;