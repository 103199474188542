import React  from 'react';
import { withRouter } from 'react-router-dom';

function About() {
    return (
        <div className="customer">
        <h3 className="title">Sambhav Solutions</h3>
        <div className="about_wrapper">
        <h3 className="sub_title"> Why we do what we do? </h3>

            <p className='about_section'>We believe that the Information Technology can make a huge difference in the world by enabling businesses to create better resources for the rest of the world to have.</p>
        </div>

        <div className="about_wrapper">
        <h3 className="sub_title">What we do?</h3>

            <p  className='about_section'>
            Sambhav Solutions is a high-end digital transformation technology solutions provider with vast experience in leading Enterprises through Digital Transformation.
Sambhav has footprints in the US, Canada and India. 
Sambhav brings over multiple decades of experience in software development, product development, integrations, API led connectivity, business process management, Data Analytics and Business Intelligence with innovative and cutting edge solutions using API , Cloud , Big Data, Data Analytics, AI and Machine Learning.

</p>
</div>
<hr className="a-spacing-base a-spacing-top-base a-divider-normal"></hr>
    </div>

    )
};
    
export default withRouter(About);