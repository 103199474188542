import React from 'react';
import {Navbar,Nav,Container} from 'react-bootstrap';

function Footer({ children }) {
    return (
        <>
            <div className="footer" id="footer">
            <Container className="footer-menu">
                        <Navbar >
                            
                            <Nav className="m-auto" id="nav">
                                <Nav.Link id="navLInk" href="/About" style={{color:'#fff'}}>About</Nav.Link>
                                <Nav.Link id="navLInk" href="/Home"  style={{color:'#fff'}}><i className="fa fa-home" aria-hidden="true" style={{fontSize:'22px'}}></i></Nav.Link>
                                <Nav.Link href="https://www.linkedin.com/in/rayavaram" style={{color:'#fff'}}> <i className="fab fa-linkedin"></i>
                            </Nav.Link>
                            </Nav>
                        </Navbar>
                        © 2020. Sambhav Solutions. All Rights Reserved.
                  </Container>              
                <div className="footer-contact">
                    Sambhav Solutions<br></br>
                    Phone Number: 469-877-9235<br></br>
                    
                   <a href="mailto:sales@esambhav.com?subject=SwaasaAI">Email Us</a><br></br>
             </div>
            </div>
        </>
    )
}

    
export default Footer;